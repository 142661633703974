import React from 'react'
import Splash from '../components/layout/Splash'

const splash = props => {
    return (
        <div>
            <Splash />
        </div>
    )
}

splash.propTypes = {

}

export default splash
