import React from 'react'
import Div100vh from 'react-div-100vh'

import K from '../../images/logo/logo-vert-level-1.svg'
import ON from '../../images/logo/logo-vert-level-2.svg'
import TO from '../../images/logo/logo-vert-level-3.svg'
import R from '../../images/logo/logo-vert-level-4.svg'



const Splash = props => {
    const { complete } = props;

    if (complete) return <div></div>
    return (
        <div
            style={{
                ...props.style,
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 100
            }}
            className="absolute h-screen w-screen bg-bgWhite"

        >
            <Div100vh
                className="flex flex-col p-16 justify-center"

            >
                <div className="block md:hidden mx-auto w-full">
                    <img className="mr-auto my-2" width="32%" src={K} alt="" />
                    <img className="ml-auto my-2" width="66.6%" src={ON} alt="" />
                    <img className="mr-auto my-2" width="66.6%" src={TO} alt="" />
                    <img className="ml-auto my-2" width="30%" src={R} alt="" />
                </div>
                <div className="hidden md:block lg:hidden max-h-screen mx-auto">
                    <img className="mr-auto my-2" width="33.3%" src={K} alt="" />
                    <img className="mx-auto my-2" width="66.6%" src={ON} alt="" />
                    <img className="mx-auto my-2" width="66.6%" src={TO} alt="" />
                    <img className="ml-auto my-2" width="33.3%" src={R} alt="" />
                </div>
                <div className="hidden lg:flex lg:w-2/3  mx-auto items-center max-w-xl">
                    <img className="lg:mr-2 xl:mr-0 lg:h-24 xl:h-full" width="30%" src={K} alt="" />
                    <img className="lg:mr-4 xl:mr-8 lg:h-24 xl:h-full" width="66.6%" src={ON} alt="" />
                    <img className="lg:mr-2 xl:mr-8 lg:h-24 xl:h-full" width="66.6%" src={TO} alt="" />
                    <img className="lg:mr-2 xl:mr-0 lg:h-24 xl:h-full" width="30%" src={R} alt="" />
                </div>

            </Div100vh>

        </div>
    )
}

export default Splash
